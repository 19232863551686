import Swiper from 'swiper';
import {Navigation, Autoplay, Scrollbar} from 'swiper/modules';
Swiper.use([ Navigation, Autoplay, Scrollbar]);

const cardSwiper = new Swiper('.swiper-image-card', {
  loop: false,
  slidesPerView: 1,
  spaceBetween: 30,
  breakpoints: {
    550: {
      slidesPerView: 2
    },
    840: {
      slidesPerView: 3
    }
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
});
