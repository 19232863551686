const check_underline = () => {
  $('.has-long-underline-color').each(function() {
    const height = $(this).height();
    if (height > 110) {
      $(this).addClass('wrapped');
    } else {
      $(this).removeClass('wrapped');
    }
  });
}

$(window).on('resize', function() {
  if($(this).width() > 1200) {
    check_underline();
  }
});
