const mobileMenuBreakpoint = 1200;
const $win = $(window);
const $body = $('body');

const $header = $('.site-header');
const $headerMenu = $('.site-header .site-header__menu');
const $headerNav = $('.site-header .site-header__main .wp-block-navigation');
const $subMenu = $('.site-header .wp-block-navigation__submenu-container')

function NavigateMobileSubMenu(level, $subMenu) {
    $subMenu = $subMenu || null;
    if($subMenu) {
        $headerMenu.find('.sub-menu[data-depth=' + level + ']').removeClass('active');
        $subMenu.addClass('active');
    }
    $headerMenu.css('transform', 'translate(' + (-100 * level) + '%)');
    $headerNav.attr('data-currentlevel', level);
}

$subMenu.each((index, element) => {
  const $this = $(element);
  $this.prepend('<div class="back-btn">Back</div>');
});

$(document).on('click', '.back-btn', (e)=> {
  const $this = $(e.currentTarget)
  $this.parent().removeClass('active')
})

$('.site-header .has-child').each(function (){
    const $self = $(this);
    const $link = $self.find('> a');
    const $subMenu = $self.find('> .wp-block-navigation__submenu-container');
    const $subMenuBack = $subMenu.find('> .sub-menu__back');

    const linkHref = $link.attr('href');

    if(linkHref !== '#' && linkHref !== '' && linkHref !== undefined) {
        const $menuItemDuplicate = $('<li class="menu-item menu-item--duplicate"></li>');
        $link.clone().appendTo($menuItemDuplicate);
        $subMenuBack.after($menuItemDuplicate);
    }

    $link.click(function (event) {
        console.log('CLICKED');
        if($win.width() <= mobileMenuBreakpoint) {
            console.log('HEY Prevent');
            event.preventDefault();
            NavigateMobileSubMenu($subMenu.attr('data-depth'), $subMenu);
        }
    });
});

$('.mobile-close, .mobile-menu-toggle').click(function () {
    $headerNav.toggleClass('open');
    $(this).toggleClass('is-active');
});

// Add header height as css var.
function setMenuHeightVar() {
    $body.css('--header-height', $header.outerHeight() + 'px');
}

$(window).on('resize load', setMenuHeightVar);
